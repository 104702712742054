import React from "react"
import { Link } from "gatsby"

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import MenuLink from './menulink'
import SignInButton from './signInButton'



const Layout = ({ title, children }) => {

  return (
    <div>
        <Navbar variant="dark" expand="lg" fixed="top">
            <Link to="/"><Navbar.Brand>{title}</Navbar.Brand></Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    
                    <Nav.Link><MenuLink to="/">Home</MenuLink></Nav.Link>
                    <Nav.Link><MenuLink to="/about">About</MenuLink></Nav.Link>
                    <Nav.Link><MenuLink to="/contact">Contact</MenuLink></Nav.Link>
                    {/*}
                    <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                    </NavDropdown>
                    {*/}
                </Nav>
                
                <SignInButton />
            </Navbar.Collapse>
        </Navbar>
        <div className="content-wrapper"
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
          }}
        >
        
        {children}

        </div>

        <footer>  
            © {new Date().getFullYear()} 
            {` `}
            {title}
        </footer>
    </div>
  )
}

export default Layout
