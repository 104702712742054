// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

import 'bootstrap/dist/css/bootstrap.min.css';

import "prismjs/themes/prism.css"
import "./src/styles/global.css"

const React = require("react")
const Layout = require("./src/components/layout").default

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout title={'Subflipper'} {...props}>{element}</Layout>
}