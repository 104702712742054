import React, { useState } from "react"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AppConfig from '../appconfig';


export function AddListingModal(props) {
	const [createListingFeedback, setCreateListingFeedback] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [startDate, setStartDate] = useState(new Date());


	const handleCreateListing = (event) => {
		if (isLoading) {
			return;
		}

		setIsLoading(true);
		
		event.preventDefault();
		event.stopPropagation();

		const form = event.currentTarget;

		createListing(form.elements.title.value, form.elements.category.value, form.elements.description.value, startDate, form.elements.price.value);
	};

	const createListing = (title, category, description, date, price) => {

		fetch(`${AppConfig.API_URL}createListing/${localStorage.getItem("userId")}/${localStorage.getItem("sessionId")}/${title}/${description}/${category}/${date.getTime()}/${price}`)
		.then(res => res.json())
		.then(
			result => {
				if (result.success) {
					// Create listing successful
					props.toggleShowSuccessModal();
					props.onHide();
				} else {
					setCreateListingFeedback('Could not create the listing. Try again later.');
					if (result.errorCode == 2) {
						// No session found, logout user.
						props.logoutUser();
					} else {
						
					}
				}
				setIsLoading(false);
			},
			error => {
				console.log("Failure");
				setIsLoading(false);
				setCreateListingFeedback('Could not create the listing. Try again later.');
				var timer = setTimeout(function() {
					setCreateListingFeedback('');
					clearTimeout(timer);
				}, 5000);
			}
		);

		/*
		var payload = {
			a: localStorage.getItem("userId"),
			b: 2
		};

		var data = new FormData();
		data.append( "json", JSON.stringify( payload ) );

	 	fetch(url,
			{
			    method: "POST",
			    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
			    body: data
			})
		.then(res => res.json())
		.then(
			result => {
				if (result.success) {
					// User is still logged in. 
				} else {
					localStorage.removeItem("userId");
					localStorage.removeItem("sessionId");
					localStorage.removeItem("loginTokenExpires");
					// User is not logged in anymore.
				}
			},
			error => {
				localStorage.removeItem("userId");
				localStorage.removeItem("sessionId");
				localStorage.removeItem("loginTokenExpires");

			}
		);
		*/
		return false;
	}


	return (
		<Modal
			{...props}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			dialogClassName="modal-40w"
		>

			<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						Sell a subscription
					</Modal.Title>
			</Modal.Header>

			<Modal.Body>

			<Form onSubmit={handleCreateListing}>
				<Form.Group>
					<Form.Label>Title</Form.Label>
					<Form.Control required type="text" name="title" placeholder="Enter title" />
					<Form.Control.Feedback type="invalid">
						Please enter a valid email.
					</Form.Control.Feedback>
				</Form.Group>

				<Form.Group controlId="exampleForm.ControlSelect1">
					<Form.Label>Category</Form.Label>
					<Form.Control name="category" as="select">
						<option>Media</option>
						<option>Tools</option>
						<option>Other</option>
					</Form.Control>
				</Form.Group>

				<Form.Group>
					<Form.Label>Expires</Form.Label>
						<DatePicker dateFormat="MMMM d, yyyy" selected={startDate} onChange={date => setStartDate(date)} />
				</Form.Group>

				<Form.Label htmlFor="inlineFormInputGroupUsername2" srOnly>
					Price
				</Form.Label>
				<InputGroup className="mb-2 mr-sm-2">
					<InputGroup.Prepend>
						<InputGroup.Text>$</InputGroup.Text>
					</InputGroup.Prepend>
					<FormControl name="price" type="number" placeholder="Price" />
				</InputGroup>

				<Form.Group controlId="exampleForm.ControlTextarea1">
					<Form.Label>Description</Form.Label>
					<Form.Control required minlength="35" maxlength="800" as="textarea" name="description" placeholder="Describe the subscription you're selling" rows="6" />
				</Form.Group>

				<p className="feedback">{createListingFeedback}</p>
				<Button type="submit" disabled={isLoading}>{isLoading ? 'Creating..' : 'Create'}</Button>
			</Form>

			</Modal.Body>
		</Modal>
	);
}

export function SuccessModal(props) {

	return (
		<Modal
			{...props}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			dialogClassName="modal-40w"
		>

			<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						Sell a subscription
					</Modal.Title>
			</Modal.Header>

			<Modal.Body>

			<p>Listing successfully created!</p>

			</Modal.Body>
		</Modal>
	);
}