import React, { useState } from "react"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DatePicker from "react-datepicker";
import {AddListingModal, SuccessModal} from './addListingModal';
import AppConfig from '../appconfig';



export default class SignInButton extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
			showSignInButton: false,
			showModal: false, 
			showSuccessModal: false, 
			showRegister: false,
			loggedIn: false
		};

		this.toggleShowModal = this.toggleShowModal.bind(this);
		this.toggleShowSuccessModal = this.toggleShowSuccessModal.bind(this);
		this.toggleShowRegister = this.toggleShowRegister.bind(this);
		this.setLoggedIn = this.setLoggedIn.bind(this);
		this.validateUser = this.validateUser.bind(this);
		this.validateLogin = this.validateLogin.bind(this);
		this.logoutUser = this.logoutUser.bind(this);
		this.setShowSignInButton = this.setShowSignInButton.bind(this);
	}

	componentDidMount() {
		this.validateUser();
	}

	validateUser() {
		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');
		let loginTokenExpires = localStorage.getItem('loginTokenExpires');
		if (userId == undefined || userId == '' || 
			sessionId == undefined || sessionId == '' ||
			loginTokenExpires == undefined || loginTokenExpires == '') {
			// Not logged in.
			this.setLoggedIn(false);
			this.setShowSignInButton(true);
		} else {
			// Logged in, check if the login time has expired.
			let currentTime = new Date();
			let loginExpiryTime = new Date(loginTokenExpires)
			if (currentTime.getTime() < loginExpiryTime.getTime()) {
				// The login time has not expired, validate the login and keep the user logged in with the same variables.
				this.validateLogin(userId, sessionId);
			} else {
				// The login time has expired, logout the user.
				this.logoutUser();
				this.setShowSignInButton(true);
			}	
		}
	}

	validateLogin(userId, sessionId) {
		let url = AppConfig.API_URL + 'validateLogin';

		url += ('/' + userId);
		url += ('/' + sessionId);

	 	fetch(url)
		.then(res => res.json())
		.then(
			result => {
				if (result.success) {
					// User is still logged in. 
					this.setLoggedIn(true);
				} else {
					localStorage.removeItem("userId");
					localStorage.removeItem("sessionId");
					localStorage.removeItem("loginTokenExpires");
					this.setLoggedIn(false);
					// User is not logged in anymore.
				}
				this.setShowSignInButton(true);
			},
			error => {
				localStorage.removeItem("userId");
				localStorage.removeItem("sessionId");
				localStorage.removeItem("loginTokenExpires");
				this.setLoggedIn(false);
				// Error, user should not be logged in.
				this.setShowSignInButton(true);
			}
		);

		return false;
	}

	logoutUser() {
		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');

		let url = AppConfig.API_URL + 'logout';

		localStorage.removeItem("userId");
		localStorage.removeItem("sessionId");
		localStorage.removeItem("loginTokenExpires");

		url += ('/' + userId);
		url += ('/' + sessionId);

	 	fetch(url)
		.then(res => res.json())
		.then(
			result => {
				if (result.success) {
					// User was logged out.
				} else {
					console.log("Could not log out.");
				}
			},
			error => {
				console.log("Error logging out.");
			}
		);

		this.setLoggedIn(false);
		return false;
	}

	toggleShowModal() {
		this.setState({
			showModal: !this.state.showModal
		});
 	}

 	toggleShowSuccessModal() {
		this.setState({
			showSuccessModal: !this.state.showSuccessModal
		});
 	}

 	toggleShowRegister() {
		this.setState({
			showRegister: !this.state.showRegister
		});
 	}

 	setLoggedIn(loggedIn) {
		this.setState({
			loggedIn: loggedIn
		});
 	}

 	setShowSignInButton(showButton) {
		this.setState({
			showSignInButton: showButton
		});
 	}

 	render() {
 		if (this.state.showSignInButton) {

	 		if (this.state.loggedIn) {
				return (
					<div>
						<Button onClick={ () => this.toggleShowModal() } variant="outline-info">Sell subscription</Button>
						<Button className="signout" onClick={ () => this.logoutUser() } variant="dark">Sign out</Button>
						
						<AddListingModal
							show={this.state.showModal}
							onHide={() => this.toggleShowModal()}
							logoutUser={() => this.logoutUser()}
							toggleShowSuccessModal = {() => this.toggleShowSuccessModal()}
							setLoggedIn={() => this.setLoggedIn(true)}
						/>						
						
						<SuccessModal
							show={this.state.showSuccessModal}
							onHide={() => this.toggleShowSuccessModal()}
						/>

					</div>
				);
			} else {
				return (
					<div>
						<Button onClick={ () => this.toggleShowModal() } id="sign-in-button" variant="outline-info">Sign In</Button>

						<SignInModal
							show={this.state.showModal}
							onHide={() => this.toggleShowModal()}
							setLoggedIn={() => this.setLoggedIn(true)}
							toggleRegister={() => this.toggleShowRegister()}
							showRegister={this.state.showRegister}
						/>
					</div>
				);
			}
	 	} else {
	 		return null;
	 	}
	 }

}



function SignInModal(props) {
	const [passwordValidated, setPasswordValidated] = useState("");
	const [signUpFeedback, setSignUpFeedback] = useState("");
	const [signInFeedback, setSignInFeedback] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	

	const handleSignIn = (event) => {
		if (isLoading) {
			return;
		}

		setIsLoading(true);

		event.preventDefault();
		event.stopPropagation();

		const form = event.currentTarget;

		signIn(form.elements.email.value, form.elements.password.value);
	};

	const handleSignUp = (event) => {
		if (isLoading) {
			return;
		}
		
		event.preventDefault();
		event.stopPropagation();

		const form = event.currentTarget;
		if (form.elements.password.value != form.elements.confirmPassword.value) {
			setPasswordValidated("true");
			return;
		} else {
			setIsLoading(true);
			setPasswordValidated("");
			signUp(form.elements.email.value, form.elements.password.value, form);
		}
	};


	const signIn = (email, password) => {
		fetch(AppConfig.API_URL + 'login/' + email + '/' + password)
		.then(res => res.json())
		.then(
			result => {
				if (result.errorCode !== 0) {
					setSignInFeedback('Either the email or password you entered was wrong.');
					var timer = setTimeout(function() {
						setSignInFeedback('');
						clearTimeout(timer);
					}, 5000)
				} else {
					if (result.success) {
						loginUser(result);
					} else {
						setSignInFeedback('Could not sign in. Try again later.');
					}
				}
				setIsLoading(false);
			},
			error => {
				console.log("Failure");
				setIsLoading(false);
				setSignInFeedback('Could not sign in. Try again later.');
				var timer = setTimeout(function() {
					setSignInFeedback('');
					clearTimeout(timer);
				}, 5000);
			}
		);
	}

	const loginUser = (data) => {
		let date = new Date();
		date = date.addDays(90);

		localStorage.setItem("userId", data.userId);
		localStorage.setItem("sessionId", data.sessionId);
		localStorage.setItem("loginTokenExpires", date);

		props.onHide();
		props.setLoggedIn();
	}

	const signUp = (email, password, form) => {
		
		fetch(AppConfig.API_URL + 'register/' + email + '/' + password)
		.then(res => res.json())
		.then(
			result => {
				if (result.success) {
					signIn(email, password);
				} else {
					let feedback = 'Could not complete sign up. Try again later.';
					if (result.errorCode == 2) {
						feedback = 'You already have an account, please log in.';
					}

					setSignUpFeedback(feedback);
					var timer = setTimeout(function() {
						setSignUpFeedback('');
						clearTimeout(timer);
					}, 5000);
				}
				setIsLoading(false);
			},
			error => {
				console.log("Failure");
				setIsLoading(false);
				setSignUpFeedback('Could not complete sign up. Try again later.');
				var timer = setTimeout(function() {
					setSignUpFeedback('');
					clearTimeout(timer);
				}, 5000);
			}
		);
		
	}

	if (props.showRegister) {
		return (
			<Modal
				{...props}
				size="sm"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>

				<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
							Sign Up
						</Modal.Title>
				</Modal.Header>

				<Modal.Body>

				<Form onSubmit={handleSignUp}>
					<Form.Group>
						<Form.Label>Email adress</Form.Label>
						<Form.Control required type="email" name="email" placeholder="Enter email" />
						<Form.Control.Feedback type="invalid">
							Please enter a valid email.
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group controlId="validationCustom04">
						<Form.Label>Password</Form.Label>
						<Form.Control name="password" type="password" placeholder="Password" required />
						<Form.Control.Feedback type="invalid">
							Please choose a password.
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group controlId="validationCustom04">
						<Form.Label>Confirm assword</Form.Label>
						<Form.Control name="confirmPassword" isInvalid={passwordValidated} type="password" placeholder="Password" required />
						<Form.Control.Feedback type="invalid">
							The passwords are not a match
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group controlId="formBasicCheckbox">
						<Form.Check required type="checkbox" label="I have read and agree to the" />
						<a target="_blank" href="/terms">Terms of Service</a> and <a target="_blank" href="/privacy">Privacy policy</a>
					</Form.Group>

					<p className="feedback">{signUpFeedback}</p>
					<Button type="submit" disabled={isLoading}>{isLoading ? 'Signing up..' : 'Sign Up'}</Button>
				</Form>

				</Modal.Body>
				<Modal.Footer style={{justifyContent: "space-between"}}>
					<Button variant="outline-primary" onClick={props.onHide}>Close</Button>
					<Button variant="outline-primary" onClick={props.toggleRegister}>Sign In</Button>
				</Modal.Footer>
			</Modal>
		);
	} else {
		return (
			<Modal
				{...props}
				size="sm"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>

				<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
							Sign In
						</Modal.Title>
				</Modal.Header>

				<Modal.Body>

					<Form onSubmit={handleSignIn}>
						<Form.Group controlId="validationCustomEmail">
							<Form.Label>Email</Form.Label>
							<InputGroup>
								<Form.Control required type="email" name="email" placeholder="Enter email" />
								<Form.Control.Feedback type="invalid">
									Incorrect
								</Form.Control.Feedback>
							</InputGroup>
						</Form.Group>

						<Form.Group controlId="validationCustom04">
							<Form.Label>Password</Form.Label>
							<Form.Control name="password" type="password" placeholder="Password" required />
							<Form.Control.Feedback type="invalid">
								Incorrect
							</Form.Control.Feedback>
						</Form.Group>
					
						<p className="feedback">{signInFeedback}</p>
						<Button type="submit" disabled={isLoading}>{isLoading ? 'Signing in..' : 'Sign In'}</Button>
					</Form>

				</Modal.Body>
				<Modal.Footer style={{justifyContent: "space-between"}}>
					<Button variant="outline-primary" onClick={props.onHide}>Close</Button>
					<Button variant="outline-primary" onClick={props.toggleRegister}>Sign Up</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}




Date.prototype.addDays = function(days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}